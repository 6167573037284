<template>
	<!--注意： 表单中需要右对齐的才加  class="form-title"-->
	<!--注意： 表单中需要右对齐的才加  class="form-title"-->
	<!--注意： 表单中需要右对齐的才加  class="form-title"-->
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
				</p>
				<div class="h-b">
					<el-button v-if="this.$store.state.global.currentType == 2" type="primary" size="small"
						@click="add" v-right-code="'Order:Getemptyorder'">新增</el-button>
				</div>
				<span v-if="config.isAdd">
					<div class="h-b">
						<el-button type="primary" size="small" @click="save" v-right-code="'Order:Getemptyorder'">保存</el-button>
					</div>
				</span>
				<span v-else>
					<div class="h-b">
						<el-button v-if="config.isCanEdit" type="primary" size="small" @click="save" v-right-code="'Order:Getemptyorder'">保存</el-button>
					</div>
				</span>
			</div>
		</div>
		<el-tabs type="border-card" v-model.trim="activeName">
			<el-tab-pane label="基本信息" name="tabbasic">
				<el-collapse v-model.trim="collapseItem">
					<el-collapse-item title="基本信息" name="basicinfo">
						<el-form ref="soForm" :model="dataSource" :rules="soValidate">
							<el-form-item>
								<el-col :span="2" class="form-title"><span style="color:red;">*</span>货主：</el-col>
								<el-col :span="5">
									<el-form-item>
										<ChooseCustomer :readonly="!config.isAdd" v-model="dataSource.CustomerCode"
											:customerName.sync="dataSource.CustomerName" :onChange="customerChange">
										</ChooseCustomer>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">制单人：</el-col>
								<el-col :span="5">
									<el-form-item>
										<!-- <span>{{dataSource.CreatedUser}}</span> -->
										<LookPopup v-model="dataSource.CreatedUserName" dataTitle="用户选择"
											dataUrl='omsapi/sysuser/getlist' syncField='UserName'
											@syncFieldEvent="syncCreateUserEvent"></LookPopup>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item v-if="!config.isAdd">
								<el-col :span="2" class="form-title">标准单号：</el-col>
								<el-col :span="5">
									<el-form-item>
										<span>{{ dataSource.OrderNo }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">单据状态：</el-col>
								<el-col :span="5">
									<el-form-item>
										<span>{{ dataSource.DisplayOrderStatus }}</span>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">
									原单单号：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-input v-model.trim="dataSource.SourceOrderNo" :maxlength="200"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">制单时间：</el-col>
								<el-col :span="5">
									<el-form-item prop="OrderTime">
										<el-date-picker v-model.trim="dataSource.OrderTime" placeholder="选择日期"
											type="datetime" :picker-options="pickerDisable"
											value-format="yyyy-MM-dd HH:mm:ss">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									<span style="color:red;">*</span>订单类型：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="OrderType">
										<DictionarySelect v-model="dataSource.OrderType" dicTypeCode="OrderTypeCode"
											datType="Int"></DictionarySelect>
										<!-- <el-select v-model.trim="dataSource.OrderType" :maxlength="2">
                                            <el-option v-for="item in dataSource.OrderTypes" :key="item.Value"
                                                :label="item.Label" :value="item.Value">
                                            </el-option>
                                        </el-select> -->
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">出库仓库：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="WarehouseName">
										<el-input v-model.trim="dataSource.WarehouseName" readonly
											suffix-icon="el-icon-search" :maxlength="100" :minlength="2"
											placeholder="请选择仓库"
											@click.native="warehouseOptions.visible = true; warehouseOptions.warehouseType = 100">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									入库仓库：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="DbWarehouseName">
										<el-input v-model.trim="dataSource.DbWarehouseName" readonly
											suffix-icon="el-icon-search" :maxlength="100" :minlength="2"
											placeholder="请选择仓库"
											@click.native="warehouseOptions.visible = true; warehouseOptions.warehouseType = 200">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">来源系统：</el-col>
								<el-col :span="5">
									<el-form-item prop="ExternalIdentity">
										<el-select v-model.trim="dataSource.ExternalIdentity" :maxlength="2"
											no-data-text="" @change="selectExternalSystemChange">
											<el-option v-for="item in ExternalSystemNames" :key="item.Value"
												:label="item.Label" :value="item.Value">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<!-- <el-col :span="3" class="form-title">上门取货：</el-col>
                                <el-col :span="5">
                                    <el-form-item prop="IsVisit">
                                        <el-select v-model.trim="dataSource.OrderExtend.IsVisit" :maxlength="2"
                                            no-data-text="">
                                            <el-option v-for="item in VisitTakeGoods" :key="item.value"
                                                :label="item.label" :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col> -->
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">
									客户：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<LookPopup v-model="dataSource.ClientCode" dataTitle="客户选择"
											dataUrl='omsapi/customervendor/getlist' syncField='ClientCode'
											@syncFieldEvent="syncClientEvent" rules="soValidate.ClientCode"></LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									供应商：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<LookPopup v-model="dataSource.SupplierCode" dataTitle="供应商选择"
											dataUrl='omsapi/customervendor/getlist' syncField='SupplierCode'
											@syncFieldEvent="syncSupplierEvent" rules="soValidate.SupplierCode">
										</LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>越库标识：</el-col>
								<el-col :span="5">
									<el-form-item prop="IsCrossDocking">
										<el-switch v-model.trim="dataSource.IsCrossDocking"></el-switch>
									</el-form-item>
								</el-col>
								<!-- <el-col :span="3" class="form-title">店铺：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-input v-model.trim="dataSource.ShopNick" readonly icon="search"
                                            :maxlength="100" :minlength="2" placeholder="请选择店铺"
                                            @click.native="shopOptions.visible = true"></el-input>
                                    </el-form-item>
                                </el-col> -->
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">
									预计发货时间：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-date-picker v-model.trim="dataSource.DeliverGoodsTime" placeholder="选择日期"
											type="datetime" :picker-options="pickerDisable"
											value-format="yyyy-MM-dd HH:mm:ss">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									预计到货时间：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-date-picker v-model.trim="dataSource.OrderExtend.ScheduledProcessDate"
											placeholder="选择日期" type="datetime" :picker-options="pickerDisable"
											value-format="yyyy-MM-dd HH:mm:ss">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">紧急程度：</el-col>
								<el-col :span="5">
									<el-form-item prop="EmergencyDegree">
										<DictionarySelect v-model="dataSource.EmergencyDegree"
											dicTypeCode="EmergencyDegreeCode" datType="Int"></DictionarySelect>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title"> 到货方式：</el-col>
								<el-col :span="5">
									<el-form-item>
										<DictionarySelect v-model="dataSource.ArrivalType" dicTypeCode="ArrivalTypeCode"
											datType="Int"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									总数量：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-input v-model.trim="dataSource.TotalNumber" type="number"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									总金额：
								</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-input v-model.trim="dataSource.OrderAmt" type="number"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item v-if="dataSource.OrderStatus === 300">
								<el-col :span="2" class="form-title">取消时间：</el-col>
								<el-col :span="5">
									<span>{{ dataSource.CancelTime }}</span>
								</el-col>
								<el-col :span="3" class="form-title">取消人：</el-col>
								<el-col :span="5">
									<span>{{ dataSource.CancelUserName }}</span>
								</el-col>
								<el-col :span="3" class="form-title">取消原因：</el-col>
								<el-col :span="5">
									<span>{{ dataSource.CancelRemarks }}</span>
								</el-col>
							</el-form-item>
							<el-form-item v-if="dataSource.InterceptStatus === 1">
								<el-col :span="2" class="form-title">拦截标识：</el-col>
								<el-col :span="5">
									<span>是</span>
								</el-col>
								<el-col :span="3" class="form-title">拦截人：</el-col>
								<el-col :span="5">
									<span>{{ dataSource.InterceptUserName }}</span>
								</el-col>
								<el-col :span="3" class="form-title">拦截原因：</el-col>
								<el-col :span="5">
									<span>{{ dataSource.InterceptRemarks }}</span>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">
									订单备注：
								</el-col>
								<el-col :span="21">
									<el-form-item>
										<el-input type="textarea" v-model.trim="dataSource.OrderMemo" :maxlength="200">
										</el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="配送信息" name="transInfo">
						<el-form :model="dataSource">
							<el-form-item>
								<el-col :span="2" class="form-title"><span style="color:red;">*</span> 承运方式：</el-col>
								<el-col :span="5">
									<el-form-item>
										<DictionarySelect v-model="dataSource.DeliveryWay" dicTypeCode="DeliveryWayCode"
											datType="Int"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">承运商：</el-col>
								<el-col :span="5">
									<el-form-item prop="LogisticserName">
										<el-input v-model.trim="dataSource.LogisticserName" readonly
											suffix-icon="el-icon-search" :maxlength="100" :minlength="2"
											placeholder="请选择承运商" @click.native="carrierOptions.visible = true">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">
									承运商产品：
								</el-col>
								<el-col :span="5">
									<el-form-item prop="MemberProductName">
										<el-input v-model.trim="dataSource.MemberProductName" readonly
											suffix-icon="el-icon-search" :maxlength="100" :minlength="2"
											placeholder="请选择承运商产品" @click.native="carrierProductAlert"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title"> 运输方式：</el-col>
								<el-col :span="5">
									<el-form-item>
										<DictionarySelect v-model="dataSource.ShippingType"
											dicTypeCode="ShippingTypeCode" datType="Int"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"> 配送方式：</el-col>
								<el-col :span="5">
									<el-form-item>
										<DictionarySelect v-model="dataSource.TransportType"
											dicTypeCode="TransportTypeCode" datType="Int"></DictionarySelect>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"> 是否投保：</el-col>
								<el-col :span="5">
									<el-form-item>
										<el-switch v-model.trim="dataSource.OrderExtend.IsDeclaration"></el-switch>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="发货人信息" name="senderinfo">
						<el-form ref="senderForm" :model="dataSource" :rules="senderValidate">
							<el-form-item>
								<el-col :span="2" class="form-title">发货地址编码：</el-col>
								<el-col :span="5">
									<el-form-item prop="SenderAddressCode">
										<el-input v-model.trim="dataSource.SenderAddressCode" readonly
											suffix-icon="el-icon-search" :maxlength="100" :minlength="2" placeholder=""
											@click.native="receiverOptions.visible = true, receiverOptions.addressType = 100">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">发货方：</el-col>
								<el-col :span="5">
									<el-form-item prop="SenderCompany">
										<el-input v-model.trim="dataSource.SenderCompany" :maxlength="100"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">联系人：</el-col>
								<el-col :span="5">
									<el-form-item prop="SenderName">
										<el-input v-model.trim="dataSource.DisplaySenderName" v-if="!config.isCanEdit"
											disabled></el-input>
										<el-input v-model.trim="dataSource.SenderName" :maxlength="100" :minlength="2"
											placeholder="联系人" v-else></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">联系电话：</el-col>
								<el-col :span="5">
									<el-form-item prop="SenderMobile">
										<el-input v-model.trim="dataSource.DisplaySenderMobile" v-if="!config.isCanEdit"
											disabled></el-input>
										<el-input v-model.trim="dataSource.SenderMobile" :maxlength="200"
											placeholder="联系电话" v-else></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">发货地址：</el-col>
								<el-col :span="7">
									<Distpicker :placeholders="dataSource.SenderSource" :showTown="true"></Distpicker>
								</el-col>
								<el-col :span="6">
									<el-form-item prop="SenderAddress">
										<el-input v-model.trim="dataSource.DisplaySenderAddress"
											v-if="!config.isCanEdit" disabled></el-input>
										<el-input v-model.trim="dataSource.SenderAddress" :maxlength="200"
											placeholder="详细地址" v-else></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<!-- <el-form-item v-if="config.isCanEdit">
                                <el-col :span="3" class="form-title">
                                    <el-button type="primary" size="small" @click.native="preservationadd">保存至常用发货地址
                                    </el-button>
                                </el-col>
                            </el-form-item> -->
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="收货人信息" name="receiverinfo">
						<el-form ref="receiverForm" :model="dataSource" :rules="receiverValidate">
							<el-form-item>
								<el-col :span="2" class="form-title">收货地址编码：</el-col>
								<el-col :span="5">
									<el-form-item prop="ReceiverAddressCode">
										<el-input v-model.trim="dataSource.ReceiverAddressCode" readonly
											suffix-icon="el-icon-search"
											@click.native="receiverOptions.visible = true, receiverOptions.addressType = 200">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">收货方：</el-col>
								<el-col :span="5">
									<el-form-item prop="ReceiverCompany">
										<el-input v-model.trim="dataSource.ReceiverCompany" :maxlength="100"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">联系人：</el-col>
								<el-col :span="5">
									<el-form-item prop="ReceiverName">
										<el-input v-model.trim="dataSource.DisplayReceiverName" v-if="!config.isCanEdit"
											disabled></el-input>
										<el-input v-model.trim="dataSource.ReceiverName" :maxlength="100" :minlength="2"
											placeholder="联系人" v-else></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="2" class="form-title">联系电话：</el-col>
								<el-col :span="5">
									<el-form-item prop="ReceiverMobile">
										<el-input v-model.trim="dataSource.DisplayReceiverMobile"
											v-if="!config.isCanEdit" disabled></el-input>
										<el-input v-model.trim="dataSource.ReceiverMobile" :maxlength="200"
											placeholder="联系电话" v-else></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">收货地址：</el-col>
								<el-col :span="7">
									<Distpicker :placeholders="dataSource.ReceiverSource" :showTown="true"></Distpicker>
								</el-col>
								<el-col :span="6">
									<el-form-item prop="ReceiverAddress">
										<el-input v-model.trim="dataSource.DisplayReceiverAddress"
											v-if="!config.isCanEdit" disabled></el-input>
										<el-input v-model.trim="dataSource.ReceiverAddress" :maxlength="200"
											placeholder="详细地址" v-else></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="商品信息" name="priductinfo">
						<div class="sl-border">
							<div class="h-handle-button">
								<div class="h-b">
									<!--已勾选无店铺权限-->
									<el-button type="primary" size="small" v-if="config.isCanEdit"
										@click.native="productOptions.visible = true">新增</el-button>
									<!--没勾选无店铺权限-->
									<!-- <el-button type="primary" size="small" v-if="config.isCanEdit && !dataSource.ShopAccess"
						                :disabled="!isSelectShop" @click.native="productOptions.visible = true">新增</el-button> -->
								</div>
								<div class="h-b">
									<el-button type="primary" size="small" v-if="config.isCanEdit" @click="importorderproduct">
										导入</el-button>
								</div>
								<div class="h-b">
									<el-button type="primary" size="small" v-if="config.isCanEdit" @click="delProductList">删除
									</el-button>
								</div>
						
							</div>
							<el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border
								@selection-change="handleSelectionChange" highlight-current-row>
								<el-table-column type="index" width="50">
								</el-table-column>
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column label="商品编码" min-width="120px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.ProductNo">{{ scope.row.ProductNo }}</span>
									</template>
								</el-table-column>
								<el-table-column label="外部编码" min-width="110px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.OuterID">{{ scope.row.OuterID }}</span>
									</template>
								</el-table-column>
								<el-table-column label="组合商品编码" min-width="110px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.ProductCombineNo">{{ scope.row.ProductCombineNo }}</span>
									</template>
								</el-table-column>
								<el-table-column label="商品名称" min-width="160px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.ProductName">{{ scope.row.ProductName }}</span>
									</template>
								</el-table-column>
								<el-table-column label="规格" min-width="100px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.SKU">{{ scope.row.SKU }}</span>
									</template>
								</el-table-column>
								<el-table-column label="商品条码" min-width="130px">
									<template slot-scope="scope">
										<span v-model.trim="scope.row.ProductBarCode">{{ scope.row.ProductBarCode }}</span>
									</template>
								</el-table-column>
								<el-table-column label="商品数量" min-width="110px">
									<template slot-scope="scope">
										<el-input v-if="config.isCanEdit" min="1" type="number"
											v-model.trim="scope.row.OrderNumber" size="mini" placeholder="数量" :maxlength="9" />
										<span v-if="!config.isCanEdit"
											v-model.trim.number.trim="scope.row.OrderNumber">{{ scope.row.OrderNumber }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
									<template slot-scope="scope">
										<el-select v-model.trim="scope.row.OrderProductUnit" size="mini" :maxlength="200">
											<el-option v-for="item in scope.row.UnitList" :key="item.ProductUnitName"
												:label="item.ProductUnitName" :value="item.ProductUnitName">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column prop="InventoryType" label="库存类型" min-width="130px">
									<template slot-scope="scope">
										<el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
											<el-option v-for="item in dataSource.InventoryTypes" :key="item.Value"
												:label="item.Label" :value="item.Value">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column label="商品单价" min-width="110px">
									<template slot-scope="scope">
										<el-input v-if="config.isCanEdit && !dataSource.IsRKBanSelectPrice"
											v-model.trim="scope.row.ProductPrice" size="mini" placeholder="单价" :maxlength="9" />
										<span v-if="config.isCanEdit && dataSource.IsRKBanSelectPrice"
											v-model.trim="scope.row.ProductPrice">{{ scope.row.ProductPrice }}</span>
										<span v-if="!config.isCanEdit"
											v-model.trim="scope.row.ProductPrice">{{ scope.row.ProductPrice }}</span>
									</template>
								</el-table-column>
						
								<!-- <el-table-column label="商品体积cm³" min-width="120px">
						            <template slot-scope="scope">
						                <el-input v-if="config.isCanEdit" min="1" type="number"
						                          v-model.trim="scope.row.EstimateTotalCubage" size="mini" placeholder="体积"
						                          :maxlength="15" />
						                <span v-if="!config.isCanEdit"
						                      v-model.trim="scope.row.EstimateTotalCubage">{{scope.row.EstimateTotalCubage}}</span>
						            </template>
						        </el-table-column> -->
								<!-- <el-table-column label="商品重量kg" min-width="120px">
						            <template slot-scope="scope">
						                <el-input v-if="config.isCanEdit" min="1" type="number"
						                          v-model.trim="scope.row.EstimateTotalWeight" size="mini" placeholder="重量"
						                          :maxlength="15" />
						                <span v-if="!config.isCanEdit"
						                      v-model.trim="scope.row.EstimateTotalWeight">{{scope.row.EstimateTotalWeight}}</span>
						            </template>
						        </el-table-column> -->
								<el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
									<template slot-scope="scope">
										<el-date-picker v-if="config.isCanEdit" v-model.trim="scope.row.ProductDate" size="mini"
											type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
										<span v-if="!config.isCanEdit"
											v-model.trim="scope.row.ProductDate">{{ scope.row.ProductDate }}</span>
									</template>
								</el-table-column>
								<el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
									<template slot-scope="scope">
										<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductBatchNo" size="mini"
											placeholder="生产批次号" :maxlength="30" />
										<span v-if="!config.isCanEdit"
											v-model.trim="scope.row.ProductBatchNo">{{ scope.row.ProductBatchNo }}</span>
									</template>
								</el-table-column>
								<el-table-column label="序列号" min-width="110px">
									<template slot-scope="scope">
										<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductSN" size="mini"
											placeholder="序列号" :maxlength="30" />
										<span v-if="!config.isCanEdit"
											v-model.trim="scope.row.ProductSN">{{ scope.row.ProductSN }}</span>
									</template>
								</el-table-column>
								<!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
								<el-table-column :label="col.LotName ? col.LotName : col.DefaultLotName"
									v-for="(col, index) in lotManages" v-if="col.IsDisplay" :key="index" min-width="140px">
									<template slot-scope="scope">
										<span v-if="config.isCanEdit">
											<el-input size="mini" v-if="col.LotType == 2"
												v-model="scope.row['LotAttr' + (index + 3)]" type="number"
												oninput="this.value=this.value.replace(/[^0-9]+/,'');"
												:placeholder="col.DefaultLotName">
											</el-input>
											<el-input size="mini" v-else-if="col.LotType == 3"
												v-model="scope.row['LotAttr' + (index + 3)]" type="number"
												oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
												:placeholder="col.DefaultLotName">
											</el-input>
											<el-date-picker v-else-if="col.LotType == 4"
												v-model="scope.row['LotAttr' + (index + 3)]" type="date"
												value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
											</el-date-picker>
											<el-select v-else-if="col.LotType == 5" v-model="scope.row['LotAttr' + (index + 3)]"
												style="width: 100%;" multiple filterable allow-create default-first-option
												:placeholder="col.DefaultLotName">
												<el-option v-for="item in col.SelectList" :key="item" :label="item"
													:value="item">
												</el-option>
											</el-select>
											<el-input size="mini" v-else v-model="scope.row['LotAttr' + (index + 3)]"
												:placeholder="col.DefaultLotName">
											</el-input>
										</span>
										<span v-else>{{ scope.row['LotAttr' + (index + 3)] }}</span>
									</template>
								</el-table-column>
								<el-table-column label="商品备注" min-width="150px">
									<template slot-scope="scope">
										<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductMemo" size="mini"
											placeholder="商品备注" :maxlength="50" />
										<span v-if="!config.isCanEdit"
											v-model.trim="scope.row.ProductMemo">{{ scope.row.ProductMemo }}</span>
									</template>
								</el-table-column>
							</el-table>
							<div style="float: left;margin-top: 5px;">
								商品共计：{{ productNum }}件；总毛重：{{ productGrossWeight }}kg；总净重：{{ productNetWeight }}kg；总金额：{{ productAmount }}元；总体积：{{ productVolume }}cm³
							</div>
							<div class="page">
								<el-pagination v-if="!config.isCanEdit" @size-change="productPageSizeChange"
									@current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
									:page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="OrderProductPage.TotalCount">
								</el-pagination>
								<el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
									刷新
								</el-button>
							</div>
						</div>
					</el-collapse-item>
					
				</el-collapse>
			</el-tab-pane>
			<el-tab-pane label="日志信息" name="tabsOrderLogs" v-if="!config.isAdd">
				<el-table :data="dataSource.OrderLogs" border highlight-current-row>
					<el-table-column prop="DisplayCreatedTime" label="创建日期">
						<template slot-scope="scope">
							<span v-model.trim="scope.row.CreatedTime">{{ scope.row.CreatedTime }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="OperateDesc" label="操作描述">
						<template slot-scope="scope">
							<span v-model.trim="scope.row.OperateDesc">{{ scope.row.OperateDesc }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="OperatorName" label="操作人">
						<template slot-scope="scope">
							<span v-model.trim="scope.row.OperateUserName">{{ scope.row.OperateUserName }}</span>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="关联单据" name="tabsOrderRelate" v-if="!config.isAdd">
				<el-tabs v-model="activeGLName" type="border-card" @tab-click="handleGLClick">
					<el-tab-pane label="调度单" name="first">
						<RelationOrderList pageUrl="omsapi/dispatch/getlist" :orderId="config.dataSourceKey">
						</RelationOrderList>
					</el-tab-pane>
					<el-tab-pane label="出库单" name="second">
						<RelationOrderList pageUrl="omsapi/execute/getoutlist" :standardOrderId="config.dataSourceKey">
						</RelationOrderList>
					</el-tab-pane>
					<el-tab-pane label="入库单" name="third">
						<RelationOrderList pageUrl="omsapi/execute/getinlist" :standardOrderId="config.dataSourceKey">
						</RelationOrderList>
					</el-tab-pane>
					<el-tab-pane label="运单" name="fourth">
						<RelationOrderList pageUrl="omsapi/logistics/gettranslist" :orderNo="dataSource.OrderNo">
						</RelationOrderList>
					</el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="物流节点跟踪" name="tabsOrderTrace" v-if="!config.isAdd">
				<RelationOrderList  pageUrl="omsapi/ordertraceinfo/getlist" :orderId="config.dataSourceKey">
				</RelationOrderList>
			</el-tab-pane>
			<el-tab-pane label="验收记录" name="tabsReceiveCheck" v-if="!config.isAdd">
				<el-tabs v-model="activeYSName" type="border-card">
					<el-tab-pane label="收货检查" name="first">
						<RelationOrderList pageUrl="omsapi/dispatch/getlist" :orderNo="dataSource.OrderNo">
						</RelationOrderList>
					</el-tab-pane>
					<el-tab-pane label="发货检查" name="second">
						<RelationOrderList pageUrl="omsapi/orderreceivecheck/getlist" :orderId="config.dataSourceKey">
						</RelationOrderList>
					</el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="物流记录" name="tabsTraceRecord" v-if="!config.isAdd">
				<RelationOrderList  pageUrl="omsapi/ordertracerecord/getlist"  :orderId="config.dataSourceKey">
				</RelationOrderList>
			</el-tab-pane>
			<el-tab-pane label="附件管理" name="tabAnnex" v-if="!config.isAdd">
				<AnnexList pageUrl="omsapi/order/getfilelist" :orderId="dataSource.Id">
				</AnnexList>
			</el-tab-pane>
			<el-tab-pane label="异常信息" name="tabException" v-if="!config.isAdd">
				<RelationOrderList pageUrl="omsapi/orderexception/getlist" :orderId="config.dataSourceKey">
				</RelationOrderList>
			</el-tab-pane>
		</el-tabs>
		<el-tabs type="border-card" v-model.trim="tab2activeName" v-if="false">
			<el-tab-pane label="商品明细" name="tabOrderProduct">
				<div class="sl-border">
					<div class="h-handle-button">
						<div class="h-b">
							<!--已勾选无店铺权限-->
							<el-button type="primary" size="small" v-if="config.isCanEdit"
								@click.native="productOptions.visible = true">新增</el-button>
							<!--没勾选无店铺权限-->
							<!-- <el-button type="primary" size="small" v-if="config.isCanEdit && !dataSource.ShopAccess"
                                :disabled="!isSelectShop" @click.native="productOptions.visible = true">新增</el-button> -->
						</div>
						<div class="h-b">
							<el-button type="primary" size="small" v-if="config.isCanEdit" @click="importorderproduct">
								导入</el-button>
						</div>
						<div class="h-b">
							<el-button type="primary" size="small" v-if="config.isCanEdit" @click="delProductList">删除
							</el-button>
						</div>

					</div>
					<el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border
						@selection-change="handleSelectionChange" highlight-current-row>
						<el-table-column type="index" width="50">
						</el-table-column>
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="商品编码" min-width="120px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.ProductNo">{{ scope.row.ProductNo }}</span>
							</template>
						</el-table-column>
						<el-table-column label="外部编码" min-width="110px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.OuterID">{{ scope.row.OuterID }}</span>
							</template>
						</el-table-column>
						<el-table-column label="组合商品编码" min-width="110px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.ProductCombineNo">{{ scope.row.ProductCombineNo }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品名称" min-width="160px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.ProductName">{{ scope.row.ProductName }}</span>
							</template>
						</el-table-column>
						<el-table-column label="规格" min-width="100px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.SKU">{{ scope.row.SKU }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品条码" min-width="130px">
							<template slot-scope="scope">
								<span v-model.trim="scope.row.ProductBarCode">{{ scope.row.ProductBarCode }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品数量" min-width="110px">
							<template slot-scope="scope">
								<el-input v-if="config.isCanEdit" min="1" type="number"
									v-model.trim="scope.row.OrderNumber" size="mini" placeholder="数量" :maxlength="9" />
								<span v-if="!config.isCanEdit"
									v-model.trim.number.trim="scope.row.OrderNumber">{{ scope.row.OrderNumber }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
							<template slot-scope="scope">
								<el-select v-model.trim="scope.row.OrderProductUnit" size="mini" :maxlength="200">
									<el-option v-for="item in scope.row.UnitList" :key="item.ProductUnitName"
										:label="item.ProductUnitName" :value="item.ProductUnitName">
									</el-option>
								</el-select>
							</template>
						</el-table-column>
						<el-table-column prop="InventoryType" label="库存类型" min-width="130px">
							<template slot-scope="scope">
								<el-select v-model.trim="scope.row.InventoryType" size="mini" :maxlength="200">
									<el-option v-for="item in dataSource.InventoryTypes" :key="item.Value"
										:label="item.Label" :value="item.Value">
									</el-option>
								</el-select>
							</template>
						</el-table-column>
						<el-table-column label="商品单价" min-width="110px">
							<template slot-scope="scope">
								<el-input v-if="config.isCanEdit && !dataSource.IsRKBanSelectPrice"
									v-model.trim="scope.row.ProductPrice" size="mini" placeholder="单价" :maxlength="9" />
								<span v-if="config.isCanEdit && dataSource.IsRKBanSelectPrice"
									v-model.trim="scope.row.ProductPrice">{{ scope.row.ProductPrice }}</span>
								<span v-if="!config.isCanEdit"
									v-model.trim="scope.row.ProductPrice">{{ scope.row.ProductPrice }}</span>
							</template>
						</el-table-column>

						<!-- <el-table-column label="商品体积cm³" min-width="120px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" min="1" type="number"
                                          v-model.trim="scope.row.EstimateTotalCubage" size="mini" placeholder="体积"
                                          :maxlength="15" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.EstimateTotalCubage">{{scope.row.EstimateTotalCubage}}</span>
                            </template>
                        </el-table-column> -->
						<!-- <el-table-column label="商品重量kg" min-width="120px">
                            <template slot-scope="scope">
                                <el-input v-if="config.isCanEdit" min="1" type="number"
                                          v-model.trim="scope.row.EstimateTotalWeight" size="mini" placeholder="重量"
                                          :maxlength="15" />
                                <span v-if="!config.isCanEdit"
                                      v-model.trim="scope.row.EstimateTotalWeight">{{scope.row.EstimateTotalWeight}}</span>
                            </template>
                        </el-table-column> -->
						<el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
							<template slot-scope="scope">
								<el-date-picker v-if="config.isCanEdit" v-model.trim="scope.row.ProductDate" size="mini"
									type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
								<span v-if="!config.isCanEdit"
									v-model.trim="scope.row.ProductDate">{{ scope.row.ProductDate }}</span>
							</template>
						</el-table-column>
						<el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
							<template slot-scope="scope">
								<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductBatchNo" size="mini"
									placeholder="生产批次号" :maxlength="30" />
								<span v-if="!config.isCanEdit"
									v-model.trim="scope.row.ProductBatchNo">{{ scope.row.ProductBatchNo }}</span>
							</template>
						</el-table-column>
						<el-table-column label="序列号" min-width="110px">
							<template slot-scope="scope">
								<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductSN" size="mini"
									placeholder="序列号" :maxlength="30" />
								<span v-if="!config.isCanEdit"
									v-model.trim="scope.row.ProductSN">{{ scope.row.ProductSN }}</span>
							</template>
						</el-table-column>
						<!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
						<el-table-column :label="col.LotName ? col.LotName : col.DefaultLotName"
							v-for="(col, index) in lotManages" v-if="col.IsDisplay" :key="index" min-width="140px">
							<template slot-scope="scope">
								<span v-if="config.isCanEdit">
									<el-input size="mini" v-if="col.LotType == 2"
										v-model="scope.row['LotAttr' + (index + 3)]" type="number"
										oninput="this.value=this.value.replace(/[^0-9]+/,'');"
										:placeholder="col.DefaultLotName">
									</el-input>
									<el-input size="mini" v-else-if="col.LotType == 3"
										v-model="scope.row['LotAttr' + (index + 3)]" type="number"
										oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
										:placeholder="col.DefaultLotName">
									</el-input>
									<el-date-picker v-else-if="col.LotType == 4"
										v-model="scope.row['LotAttr' + (index + 3)]" type="date"
										value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
									</el-date-picker>
									<el-select v-else-if="col.LotType == 5" v-model="scope.row['LotAttr' + (index + 3)]"
										style="width: 100%;" multiple filterable allow-create default-first-option
										:placeholder="col.DefaultLotName">
										<el-option v-for="item in col.SelectList" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
									<el-input size="mini" v-else v-model="scope.row['LotAttr' + (index + 3)]"
										:placeholder="col.DefaultLotName">
									</el-input>
								</span>
								<span v-else>{{ scope.row['LotAttr' + (index + 3)] }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品备注" min-width="150px">
							<template slot-scope="scope">
								<el-input v-if="config.isCanEdit" v-model.trim="scope.row.ProductMemo" size="mini"
									placeholder="商品备注" :maxlength="50" />
								<span v-if="!config.isCanEdit"
									v-model.trim="scope.row.ProductMemo">{{ scope.row.ProductMemo }}</span>
							</template>
						</el-table-column>
					</el-table>
					<div style="float: left;margin-top: 5px;">
						商品共计：{{ productNum }}件；总毛重：{{ productGrossWeight }}kg；总净重：{{ productNetWeight }}kg；总金额：{{ productAmount }}元；总体积：{{ productVolume }}cm³
					</div>
					<div class="page">
						<el-pagination v-if="!config.isCanEdit" @size-change="productPageSizeChange"
							@current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
							:page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="OrderProductPage.TotalCount">
						</el-pagination>
						<el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
							刷新
						</el-button>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<DialogEx :options="shopOptions" title="选择店铺" @onOk="onShopOk">
			<Shop ref="shop" :config="shopConfig" @onSelectedRow="onShopSelectedRow"></Shop>
		</DialogEx>
		<DialogEx :options="carrierOptions" title="选择承运商" @onOk="onCarrierOk">
			<Carrier ref="carrier" :config="carrierConfig" @onSelectedRow="onCarrierSelectedRow"></Carrier>
		</DialogEx>
		<DialogEx :options="warehouseOptions" title="选择仓库" @onOk="onWarehouseOk">
			<Warehouse ref="warehouse" :config="warehouseConfig" @onSelectedRow="onWarehouseSelectedRow"></Warehouse>
		</DialogEx>
		<DialogCtn :options="productOptions" title="选择商品" @onOk="onProductOk" @onContinue="onContinue">
			<Product ref="product" :config="productConfig" @onSelectedRow="onProductSelectedRow"></Product>
		</DialogCtn>
		<DialogEx :options="receiverOptions" title="选择联系地址" @onOk="onReceiverOk">
			<Receiver ref="receiver" :config="receiverConfig" :addressType="receiverOptions.addressType"
				@onSelectedRow="onReceiverSelectedRow"></Receiver>
		</DialogEx>
		<DialogEx :options="ImportOrderProductOptions" title="导入订单商品明细" @onOk="onImportOrderProductOk">
			<ImportOrderProduct ref="importorderproduct" @ImportOrderProductData="ImportOrderProductData"
				:config="importOrderProductConfig"></ImportOrderProduct>
		</DialogEx>
		<DialogEx :options="carrierProductOptions" title="选择承运商产品" @onOk="onCarrierProductOk">
			<CarrierProduct ref="carrierProduct" :config="carrierProductConfig" @onSelectedRow="onCarrierProductRow">
			</CarrierProduct>
		</DialogEx>
	</div>
</template>
<script>
	export default {
		data() {
			var validateCarrierName = (rule, value, callback) => {
				if (!value) return callback(new Error('承运商名称不能为空'));
				callback();
			};
			var validateWarehouseName = (rule, value, callback) => {
				if (!value) return callback(new Error('仓库名称不能为空'));
				callback();
			};
			var validateOrderTime = (rule, value, callback) => {
				if (!value) return callback(new Error('下单时间不能为空'));
				callback();
			};
			return {
				activeGLName: 'first',
				activeYSName: "first",
				collapseItem: ['basicinfo', 'senderinfo', 'transInfo', 'receiverinfo','priductinfo'],
				productIndex: 0,
				isSelectShop: false,
				ExternalSystemNames: [],
				QimenOrderTypes: [{
					value: 100,
					label: '入库单'
				}, {
					value: 400,
					label: '退货入库'
				}],
				lotManages: [], //批属性管理数据
				proDateDisplay: false,
				proBatchNoDisplay: false,
				currentCarrierSelectedRow: {},
				currentCarrierProductSelectRow: {},
				currentWarehouseSelectedRow: {},
				currentProductSelectedRows: {},
				currentReceiverSelectedRow: {},
				activeName: 'tabbasic',
				tab2activeName: 'tabOrderProduct',
				locationFrom: 1,
				updateStatus: false,
				productNum: 0,
				productGrossWeight: 0,
				productNetWeight: 0,
				productAmount: 0,
				productVolume: 0,
				soValidate: {
					ExternalIdentity: [{
						required: true,
						message: '请选择来源系统',
						trigger: 'change'
					}],
					ClientCode: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					SupplierCode: [{
						required: true,
						message: '请选择供应商',
						trigger: 'change'
					}],
					// WarehouseName: [{ validator: validateWarehouseName, trigger: 'change' }],
					// OrderOperationType: [{ required: true, message: '请选择订单类型', trigger: 'change' }],
					OrderTime: [{
						validator: validateOrderTime,
						trigger: 'blur'
					}]
				},
				receiverValidate: {},
				senderValidate: {},
				carrierValidate: {
					//LogisticserName: [{ validator: validateCarrierName, trigger: 'blur' }]
				},
				pickerDisable: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				shopOptions: {
					visible: false,
					size: 'small'
				},
				carrierOptions: {
					visible: false,
					size: 'small'
				},
				warehouseOptions: {
					visible: false,
					size: 'small',
					warehouseType: 100, //100出库仓库 200入库仓库
				},
				productOptions: {
					visible: false,
					size: 'small'
				},
				receiverOptions: {
					addressType: 0,
					visible: false,
					size: 'small'
				},
				ImportOrderProductOptions: {
					visible: false,
					size: 'small'
				},
				shopConfig: {
					isDetailDisplay: true,
				},
				carrierConfig: {
					isDetailDisplay: true,
					IsSuccess: true,
				},
				importOrderProductConfig: {
					isDetailDisplay: true,
				},
				warehouseConfig: {
					CustomerCode: '',
					isDetailDisplay: true,
				},
				productConfig: {
					ShopNo: '',
					ShopAccess: false,
					WarehouseNo: '',
					ProductNos: [],
					isDetailDisplay: true,
					isSelectionDisplay: true,
					isOrderOperation: true,
					type: 1,
					CustomerCode: '',
				},
				receiverConfig: {
					isDetailDisplay: true,
				},
				loadding: false,
				multipleSelection: [],
				initDataSource: {},
				dataSource: {
					ReceiverSource: {
						ProvinceName: '',
						ProvinceID: '',
						CityName: '',
						CityID: '',
						CountyName: '',
						CountyID: '',
					},
					SenderSource: {
						ProvinceName: '',
						ProvinceID: '',
						CityName: '',
						CityID: '',
						CountyName: '',
						CountyID: '',
					},
					CustomerCode: '',
					ProvinceId: null,
					ProvinceName: null,
					CityId: null,
					CityName: null,
					CountyId: null,
					CountyName: null,
					ReceiverCompany: null,
					ReceiverMobile: null,
					ReceiverName: null,
					ReceiverPostCode: null,
					SenderCity: null,
					SenderCityCode: null,
					SenderCounty: null,
					SenderCountyCode: null,
					SenderMobile: null,
					SenderName: null,
					SenderPostCode: null,
					SenderProvince: null,
					SenderProvinceCode: null,
					SenderCompany: null,
					SenderPhone: null,
					ReceiverPhone: null,
					ReceiverAddressCode: null,
					SenderAddressCode: null,
					Town: null,
					SenderTown: null,
					ReceiverAddress: null,
					SenderAddress: null,
					LogisticserCode: null,
					LogisticserName: null,
					MemberProductName: null,
					MemberProductCode: null,
					EmergencyDegree: 1,
					IsCrossDocking: false,
					DeliverGoodsTime: null,
					DbWarehouseName: null,
					DbWarehouseNo: null,
					WarehouseName: null,
					WarehouseNo: null,
					OrderExtend: {
						ScheduledProcessDate: null,
						IsDeclaration: false
					},
				},
				OrderProductPage: {
					PageIndex: 1,
					PageSize: 10,
					Result: [],
					OrderId: null
				},
				DeliveryWays: [{
						label: '自提',
						value: 100
					},
					{
						label: '快递',
						value: 200
					},
					{
						label: '快运',
						value: 300
					},
					{
						label: '城配',
						value: 400
					},
					{
						label: '陆运',
						value: 500
					},
				],
				carrierProductConfig: {
					isDetailDisplay: true,
					MemberId: 0,
					MemberCode: ''
				},
				carrierProductOptions: {
					visible: false,
					size: 'small'
				},
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
		},
		mounted: function() {
			this.onExternalSystemChange();
			this.getLotManage();
		},
		computed: {
			OrderStatus() {
				return this.dataSource.OrderStatus;
			},
			OrderProductList() {
				if (this.OrderProductPage) {
					return this.OrderProductPage.Result;
				}
				return new Array();
			}
		},
		watch: {
			OrderStatus: {
				handler: function(curVal, oldVal) {
					this.productConfig.WarehouseNo = this.dataSource.WarehouseNo;
				},
				deep: true
			},
			'config.dataSourceKey': {
				handler(curVal, oldVal) {
					if (curVal && curVal > 0) {
						if (curVal != oldVal) this.addUpdate(curVal); //初始化
					} else {
						//this.add(); //新增初始化
					}
				},
				deep: true
			},
			OrderProductList: {
				handler: function(nowVal, oldVal) {
					this.productNum = 0;
					this.productGrossWeight = 0;
					this.productNetWeight = 0;
					this.productAmount = 0;
					this.productVolume = 0;
					this.productConfig.ProductNos = [];
					if (nowVal) {
						for (var i = 0; i < nowVal.length; i++) {
							var product = nowVal[i];
							this.productConfig.ProductNos.push(product.ProductNo);
							var relationToPrimary = 1;
							for (var j = 0; j < product.UnitList.length; j++) {
								if (product.UnitList[j].ProductUnitName == product.OrderProductUnit) {
									relationToPrimary = product.UnitList[j].RelationToPrimary;
									break;
								}
							}
							if (product.ProductLong != null && product.ProductWidth != null && product.ProductHeight !=
								null) {
								var num = product.OrderNumber * relationToPrimary * product.ProductLong * product
									.ProductWidth * product.ProductHeight;
								product.EstimateTotalCubage = this.Utils.toDecimal(num, 2);
							} else if (product.ProductVolume != null) {
								var num = product.OrderNumber * relationToPrimary * product.ProductVolume;
								product.EstimateTotalCubage = this.Utils.toDecimal(num, 2);
							}
							if (product.GrossWeight != null) {
								var num = product.OrderNumber * relationToPrimary * product.GrossWeight;
								product.EstimateTotalWeight = this.Utils.toDecimal(num, 2);
							}
							if (product.OrderNumber != null && product.OrderNumber * 1 > 0) {
								this.productNum = this.productNum + product.OrderNumber * 1;
							}
							if (product.GrossWeight != null && product.GrossWeight * 1 > 0) {
								this.productGrossWeight = this.productGrossWeight + product.GrossWeight * 1;
							}
							if (product.NetWeight != null && product.NetWeight * 1 > 0) {
								this.productNetWeight = this.productNetWeight + product.NetWeight * 1;
							}
							if (product.NetWeight != null && product.NetWeight * 1 > 0) {
								this.productNetWeight = this.productNetWeight + product.NetWeight * 1;
							}
							if (product.ProductPrice != null && product.ProductPrice * 1 > 0) {
								this.productAmount = this.productAmount + product.ProductPrice * 1;
							}
							if (product.ProductHeight != null && product.ProductHeight * 1 > 0 &&
								product.ProductLong != null && product.ProductLong * 1 > 0 &&
								product.ProductWidth != null && product.ProductWidth * 1 > 0) {
								this.productVolume = this.productVolume + (product.ProductHeight * product
									.ProductLong * product.ProductWidth * 1);
							}
						}
					}
				},
				deep: true
			}
		},
		methods: {
			handleGLClick(tab, event) {
				this.activeGLName = tab.name;
				console.log("213123:", tab.name + "!" + this.activeGLName);
			},
			customerChange() {
				this.warehouseConfig.CustomerCode = this.dataSource.CustomerCode
				this.productConfig.CustomerCode = this.dataSource.CustomerCode
			},
			//商品分页
			onProductRefresh() {
				this.productSearchPage(this.OrderProductPage);
			},
			productPageSizeChange(val) {
				this.OrderProductPage.PageSize = val;
				this.productSearchPage(this.OrderProductPage);
			},
			productCurrentChange(val) {
				this.OrderProductPage.PageIndex = val;
				this.productSearchPage(this.OrderProductPage);
			},
			productSearchPage(search) {
				var _this = this;
				var pageSearch = {
					PageIndex: search.PageIndex,
					PageSize: search.PageSize,
					IsNoPage: this.config.isCanEdit,
					ViewStatus: this.config.OrderDisplayTag,
					Params: {
						OrderId: search.OrderId
					},
				};
				this.$ajax.query("omsapi/order/getorderproductlist", "post", pageSearch, (result) => {
					_this.OrderProductPage.Result = result.Result;
					_this.OrderProductPage.TotalCount = result.TotalCount;
				});
			},
			//重置表单
			resetSOForm: function() {
				this.Utils.clearForm(this.dataSource);
			},
			back: function() {
				this.config.isDetailDisplay = false;
			},
			add: function() {
				var _this = this;
				this.$ajax.send("omsapi/order/getemptyorder", "get", {}, (data) => {
					_this.config.isAdd = true;
					_this.config.isNormal = true;
					_this.config.isReturn = false;
					data.Result.OrderOperationType = null;
					data.Result.OrderProductPage = {
						Result: []
					};
					_this.dataSource = {
						..._this.dataSource,
						...data.Result
					};
					_this.OrderProductPage = {
						PageIndex: 1,
						PageSize: 10,
						Result: [],
						OrderId: null
					};
					//_this.dataSource = _this.Utils.extend(_this.dataSource,data.Result);
					//_this.buildAddress(data.Result);
				});
			},
			save: function() {
				var _this = this;
				if (_this.isCanEdit) {
					_this.Utils.messageBox("订单已审核不能修改", "error");
					return;
				}
				if (_this.OrderProductPage.Result.length == 0) {
					_this.Utils.messageBox("订单没有商品信息", "error");
					return;
				}
				_this.$refs["soForm"].validate((valid1) => {
					if (!valid1) return false;
					_this.$refs["receiverForm"].validate((valid2) => {
						if (!valid2) return false;
						//验证收货信息
						//验证货品明细
						var len = _this.OrderProductPage.Result.length;
						if (len == 0) {
							_this.Utils.messageBox("请选择商品", "error");
							return false;
						}

						//商品列表校验【商品数量，单价，残次品数量值】、商品批属性类型转换
						for (var a = 0; a < len; a++) {
							if (!_this.Utils.isRegularNumber(_this.OrderProductPage.Result[a]
									.OrderNumber)) {
								_this.Utils.messageBox("商品数量只能为大于0数字", "error");
								return false;
							}
							if (_this.dataSource.IsPriceSensitive) {
								if (_this.OrderProductPage.Result[a].ProductPrice != "" && _this
									.OrderProductPage.Result[a].ProductPrice != null && _this
									.OrderProductPage.Result[a].ProductPrice != "****" && !_this.Utils
									.isRegularNumber(_this.OrderProductPage.Result[a].ProductPrice)) {
									_this.Utils.messageBox("加密商品单价只能为大于0数字", "error");
									return false;
								}
								if (_this.OrderProductPage.Result[a].ProductPrice == "****") {
									_this.OrderProductPage.Result[a].ProductPrice = _this
										.OrderProductPage.Result[a].originalProductPrice;
								}
							} else {
								if (_this.OrderProductPage.Result[a].ProductPrice != "" && _this
									.OrderProductPage.Result[a].ProductPrice != null && !_this.Utils
									.isRegularNumber(_this.OrderProductPage.Result[a].ProductPrice)) {
									_this.Utils.messageBox("商品单价只能为数字", "error");
									return false;
								}
							}
							//批属性列处理
							var orderProdcut = _this.OrderProductPage.Result[a];
							for (let index = 0; index < _this.lotManages.length; index++) {
								var lot = _this.lotManages[index];
								for (var prop in orderProdcut) {
									if (prop == lot.DefaultLotProp && lot.LotType == 5 && orderProdcut[
											prop] && typeof orderProdcut[prop] == 'object') {
										orderProdcut[prop] = orderProdcut[prop].join(',');
									}
								}
							}
						}
						_this.dataSource.OrderProductList = _this.OrderProductPage.Result;
						var url = "omsapi/order/create";
						var param = JSON.parse(JSON.stringify(_this.dataSource));

						if (_this.dataSource.OrderID && _this.dataSource.OrderID != '' && _this
							.dataSource.OrderID != _this.Utils.emptyGuid) {
							//修改
							url = "omsapi/order/edit";
						} else {
							//新增
							delete param.OrderID;
						}
						_this.buildAddress(param);
						delete param.CreatedTime;
						delete param.UpdatedTime;
						delete param.OrderProductPage;
						delete param.OrderPackagePage;
						_this.$ajax.send(url, "post", param, (data) => {
							_this.Event.$emit('onRefreshList');
							if (url == "omsapi/order/create" && data.IsSuccess) {
								_this.addUpdate(data.Result.Id, data.Result.OrderNo);
							}
							_this.Utils.messageBox(data.OperationDesc || "保存成功.", "success");
						});
					});
				});
			},
			buildAddress(dataSource) {
				if (dataSource.ReceiverSource) {
					//dataSource.ReceiverCompany = dataSource.ReceiverSource.ReceiverCompany;
					dataSource.ProvinceName = dataSource.ReceiverSource.ProvinceName;
					dataSource.ProvinceID = dataSource.ReceiverSource.ProvinceID;
					dataSource.CityName = dataSource.ReceiverSource.CityName;
					dataSource.CityID = dataSource.ReceiverSource.CityID;
					dataSource.CountyName = dataSource.ReceiverSource.CountyName;
					dataSource.CountyID = dataSource.ReceiverSource.CountyID;
					dataSource.Town = dataSource.ReceiverSource.Town;
					delete dataSource.ReceiverSource;
				}
				if (dataSource.SenderSource) {
					//dataSource.SenderCompany = dataSource.SenderSource.SenderCompany;
					dataSource.SenderProvince = dataSource.SenderSource.ProvinceName;
					dataSource.SenderProvinceCode = dataSource.SenderSource.ProvinceID;
					dataSource.SenderCity = dataSource.SenderSource.CityName;
					dataSource.SenderCityCode = dataSource.SenderSource.CityID;
					dataSource.SenderCounty = dataSource.SenderSource.CountyName;
					dataSource.SenderCountyCode = dataSource.SenderSource.CountyID;
					dataSource.SenderTown = dataSource.SenderSource.Town;
					delete dataSource.SenderSource;
				}
			},
			onShopOk: function() {
				var _this = this;
				var row = this.currentShopSelectedRow;
				if (_this.dataSource.OrderStatus == 100) {
					_this.OrderProductPage.Result = [];
				}
				if (row instanceof Object && row.ShopID != this.Utils.emptyGuid) {
					this.dataSource.ShopID = row.ShopID;
					this.dataSource.ShopNo = row.ShopNo;
					this.dataSource.ShopNick = row.ShopName;
					this.dataSource.ShopExternalNo = row.ExternalNo;
					this.productConfig.ShopNo = row.ShopNo;
					this.productConfig.ShopAccess = this.dataSource.ShopAccess;
					this.isSelectShop = true;
				}
			},
			onShopSelectedRow: function(row) {
				this.currentShopSelectedRow = row;
			},
			preservationadd: function() {
				this.customeraddressmodel = {};
				if (this.dataSource.OrderID && this.dataSource.OrderID != '' && this.dataSource.OrderID != this.Utils
					.emptyGuid) {
					this.customeraddressmodel.ProvinceName = this.dataSource.SenderProvince;
					this.customeraddressmodel.ProvinceID = this.dataSource.SenderProvinceCode;
					this.customeraddressmodel.CityName = this.dataSource.SenderCity;
					this.customeraddressmodel.CityID = this.dataSource.SenderCityCode;
					this.customeraddressmodel.CountyName = this.dataSource.SenderCounty;
					this.customeraddressmodel.CountyID = this.dataSource.SenderCountyCode;
					this.customeraddressmodel.Town = this.dataSource.SenderTown;
				} else {
					this.customeraddressmodel.ProvinceName = this.dataSource.SenderSource.ProvinceName;
					this.customeraddressmodel.ProvinceID = this.dataSource.SenderSource.ProvinceID;
					this.customeraddressmodel.CityName = this.dataSource.SenderSource.CityName;
					this.customeraddressmodel.CityID = this.dataSource.SenderSource.CityID;
					this.customeraddressmodel.CountyName = this.dataSource.SenderSource.CountyName;
					this.customeraddressmodel.CountyID = this.dataSource.SenderSource.CountyID;
					this.customeraddressmodel.Town = this.dataSource.SenderSource.Town;
				}
				this.customeraddressmodel.CompanyName = this.dataSource.SenderCompany;
				this.customeraddressmodel.CustomerAddressDetail = this.dataSource.SenderAddress;
				this.customeraddressmodel.ContactName = this.dataSource.SenderName;
				this.customeraddressmodel.ContactPhone = this.dataSource.SenderMobile;
				this.customeraddressmodel.AddressCode = this.dataSource.SenderAddressCode;
				this.customeraddressmodel.SupplierType = 200;
				this.customeraddressmodel.AddressType = 100;
				this.$ajax.send("omsapi/customeraddress/createorderaddress", "post", this.customeraddressmodel, (
					data) => {
					this.Utils.messageBox(data.OperationDesc || "保存成功.", "success");
				});
			},
			onCarrierOk: function() {
				var _this = this;
				var row = this.currentCarrierSelectedRow;
				if (row instanceof Object && row.MemberCode != null && row.MemberCode != '' && row.MemberCode !=
					undefined) {
					if (this.dataSource.LogisticserCode != row.MemberCode) {
						this.dataSource.LogisticsNo = null;
						this.dataSource.OrderExtend.MarkDestination = null;
						this.dataSource.OrderExtend.PackageName = null;
					}
					//   this.dataSource.LogisticserID = row.MemberId;
					this.dataSource.LogisticserCode = row.MemberCode;
					this.dataSource.LogisticserName = row.MemberName;
					// this.$ajax.send("omsapi/carrierinsuredrule/getcarrierinsuredrulebymembercode", "get", { memberCode: row.MemberCode }, (data) => {
					//     console.log(data.Result);
					// }, true);

				}
			},
			onCarrierSelectedRow: function(row) {
				this.currentCarrierSelectedRow = row;
			},
			onWarehouseOk: function() {
				var _this = this;
				var row = this.currentWarehouseSelectedRow;
				// if (this.dataSource.OrderStatus == 100) {
				//     this.OrderProductPage.Result = [];
				// }
				if (row instanceof Object && row.WarehouseNo != null && row.WarehouseNo != '' && row.WarehouseNo !=
					undefined) {
					if (this.warehouseOptions.warehouseType === 100) {
						this.dataSource.WarehouseId = row.WarehouseId;
						this.dataSource.WarehouseNo = row.WarehouseNo;
						this.dataSource.WarehouseName = row.WarehouseName;
						this.dataSource.WarehouserId = row.MemberId;
						this.dataSource.WarehouserCode = row.MemberCode;
						this.dataSource.WarehouserName = row.MemberName;
						this.productConfig.WarehouseNo = row.WarehouseNo;
					} else {
						this.dataSource.DbWarehouseNo = row.WarehouseNo;
						this.dataSource.DbWarehouseName = row.WarehouseName;
						this.dataSource.DbWarehouseId = row.WarehouseId;
						this.dataSource.DbWarehouserId = row.MemberId;
						this.dataSource.DbWarehouserCode = row.MemberCode;
					}
				}
			},
			onWarehouseSelectedRow: function(row) {
				this.currentWarehouseSelectedRow = row;
			},
			onProductOk: function() {
				var _this = this;
				var rows = _this.currentProductSelectedRows;
				if (!rows) return;
				for (var i = 0; i < rows.length; i++) {
					var item = rows[i];
					this.productIndex++;
					//for (var j = 0; j < _this.dataSource.OrderProductList.length; j++) {//覆盖原有商品信息
					//    if (item.ProductNo == _this.dataSource.OrderProductList[j].ProductNo) {
					//        _this.dataSource.OrderProductList.splice(j, 1);
					//        break;
					//    }
					//}
					var newRow = {
						ProductIndex: this.productIndex,
						UnitList: item.UnitList,
						ProductID: item.ProductID,
						ProductNo: item.ProductNo,
						ProductName: item.ProductName,
						OuterID: item.OuterID,
						SKU: item.SKU,
						ProductBarCode: item.ProductCode,
						OrderProductUnit: item.ProductUnitName,
						OrderNumber: (item.ProductNumber < 0 ? 0 : item.ProductNumber),
						InventoryType: 1, //(this.dataSource.DefaultInventoryType == null ? this.dataSource.InventoryTypes[0].value : parseInt(this.dataSource.DefaultInventoryType)),
						ProductPrice: item.CostPrice,
						GrossWeight: item.GrossWeight,
						ProductLong: item.ProductLong,
						ProductWidth: item.ProductWidth,
						ProductHeight: item.ProductHeight,
						ProductVolume: item.ProductVolume
					};
					if (_this.dataSource.IsRKBanSelectPrice && !_this.dataSource.IsRKComputePrice) {
						newRow.ProductPrice = "";
					}
					_this.OrderProductPage.Result.push(newRow);
				}
				_this.currentProductSelectedRows.length = 0; //清空已选择信息
			},
			onContinue: function() {
				this.Event.$emit('onRefreshProductList');
			},
			onProductSelectedRow: function(data) {
				this.currentProductSelectedRows = data;
			},
			syncClientEvent: function(row) {
				if (row) {
					this.dataSource.ClientCode = row.VendorName;
				}
			},
			syncCreateUserEvent: function(row) {
				if (row) {
					this.dataSource.CreatedUserName = row.NickName;
					this.dataSource.CreatedUser = row.UserId;
				}
			},
			syncSupplierEvent: function(row) {
				if (row) {
					this.dataSource.SupplierCode = row.VendorName;
				}
			},
			deliveryWayChange(deliveryWay) {
				//重置承运商信息
				this.dataSource.LogisticsNo = '';
				this.dataSource.LogisticserCode = "";
				this.dataSource.LogisticserName = "";
				this.dataSource.MemberProductName = "";
				this.dataSource.MemberProductCode = "";
				this.carrierConfig.IsSuccess = true;

				if (deliveryWay == 200) { //快递
					this.carrierConfig.MemberType = 2;
				} else if (deliveryWay == 300) { //快运
					this.carrierConfig.MemberType = 3;
				} else if (deliveryWay == 400) { //城配
					this.carrierConfig.MemberType = 1;
				} else { //自提
					this.carrierConfig.MemberType = 4;
				}
			},
			importorderproduct: function() {
				this.ImportOrderProductOptions.visible = true;
			},
			onImportOrderProductOk: function() {

			},
			ImportOrderProductData: function(data) {
				for (let index = 0; index < data.length; index++) {
					this.OrderProductPage.Result.push(data[index]);
				}
			},
			delProductList: function() {
				if (this.multipleSelection.length == 0) {
					this.Utils.messageBox("未选择删除数据.");
					return;
				}
				var Guid_Indexs = this.Utils.selectionsToArr(this.multipleSelection, "ProductIndex");
				for (var i = this.OrderProductPage.Result.length - 1; i >= 0; i--) {
					for (var j = 0; j < this.multipleSelection.length; j++) {
						if (this.OrderProductPage.Result[i]["ProductIndex"] == this.multipleSelection[j][
								"ProductIndex"
							]) {
							this.OrderProductPage.Result.splice(i, 1);
							break;
						}
					}
				}
			},
			onReceiverOk: function() {
				var row = this.currentReceiverSelectedRow;
				if (row instanceof Object && row.CustomerAddressID != this.Utils.emptyGuid) {
					if (this.receiverOptions.addressType == 200) {
						this.dataSource.ReceiverCompany = row.CompanyName;
						this.dataSource.ReceiverName = row.ContactName;
						this.dataSource.ReceiverMobile = row.ContactPhone;
						this.dataSource.ReceiverSource.ProvinceName = row.ProvinceName;
						this.dataSource.ReceiverSource.ProvinceID = row.ProvinceID;
						this.dataSource.ReceiverSource.CityName = row.CityName;
						this.dataSource.ReceiverSource.CityID = row.CityID;
						this.dataSource.ReceiverSource.CountyName = row.CountyName;
						this.dataSource.ReceiverSource.CountyID = row.CountyID;
						this.dataSource.ReceiverSource.Town = row.Town;
						this.dataSource.ReceiverAddress = row.CustomerAddressDetail;
					} else if (this.receiverOptions.addressType == 100) {
						this.dataSource.SenderAddressCode = row.AddressCode;
						this.dataSource.SenderCompany = row.CompanyName;
						this.dataSource.SenderName = row.ContactName;
						this.dataSource.SenderMobile = row.ContactPhone;
						this.dataSource.SenderSource.ProvinceName = row.ProvinceName;
						this.dataSource.SenderSource.ProvinceID = row.ProvinceID;
						this.dataSource.SenderSource.CityName = row.CityName;
						this.dataSource.SenderSource.CityID = row.CityID;
						this.dataSource.SenderSource.CountyName = row.CountyName;
						this.dataSource.SenderSource.CountyID = row.CountyID;
						this.dataSource.SenderSource.Town = row.Town;
						this.dataSource.SenderAddress = row.CustomerAddressDetail;
					}
				}
			},
			onReceiverSelectedRow: function(data) {
				this.currentReceiverSelectedRow = data;
			},
			handleSelectionChange(data) {
				this.multipleSelection = data;
			},
			onExternalSystemChange() {
				this.$ajax.send("omsapi/order/getexternallogordersystemlist", "get", {}, (data) => {
					this.ExternalSystemNames = [{
						Label: "平台手工订单",
						Value: "EC_WLYD"
					}];
					for (var i = 0; i < data.Result.length; i++) {
						var tmp = {};
						tmp.Value = data.Result[i].ExternalIdentity;
						tmp.Label = data.Result[i].ExternalSystemName;
						this.ExternalSystemNames.push(tmp);
					}
				}, true);
			},
			selectExternalSystemChange(value) {
				for (var i = 0; i < this.ExternalSystemNames.length; i++) {
					if (this.ExternalSystemNames[i].Value === value) {
						this.dataSource.ExternalSystemName = this.ExternalSystemNames[i].Label;
						this.dataSource.ExternalIdentity = this.ExternalSystemNames[i].Value;
					}
				}
			},
			addUpdate(OrderID) {
				var _this = this;
				this.$ajax.send("omsapi/order/getOrderInfo", "get", {
					orderId: OrderID
				}, (data) => {
					_this.config.isAdd = false;
					_this.config.isNormal = true;
					_this.config.isReturn = false;
					_this.resetSOForm();
					_this.dataSource = data.Result;
					// _this.$nextTick(function () {
					// });
				});
				//商品明细分页-初始化
				// _this.OrderProductPage = {
				//         PageIndex:1,PageSize:10,Result:[]
				//     };
				_this.OrderProductPage.OrderId = OrderID;
				_this.productSearchPage(_this.OrderProductPage);
				//初始化字段
				_this.dataSource.ReceiverSource = {};
				_this.dataSource.SenderSource = {};
				if (_this.dataSource.OrderStatus == 100) {
					_this.config.isCanEdit = true;
				} else {
					_this.config.isCanEdit = false;
				}
			},
			isReturnOrder() {
				return this.dataSource.OrderOperationType == 501 ? true : false;
			},
			//批属性管理数据
			getLotManage() {
                if(!this.dataSource.CustomerCode){
                    return;
                }
				this.$ajax.send("omsapi/stockLotManage/findList?customerCode="+this.dataSource.CustomerCode, "get", {}, (data) => {
					for (let index = 0; index < data.Result.length; index++) {
						var lot = data.Result[index];
						if (lot.DefaultLotProp == 'ProductDate' && lot.IsDisplay) {
							this.proDateDisplay = true;
						}
						if (lot.DefaultLotProp == 'ProductBatchNo' && lot.IsDisplay) {
							this.proBatchNoDisplay = true;
						}
						if (lot.Sort > 2) { //排除默认的四个属性和批属性1、2
							this.lotManages.push(lot);
						}
					}
				});
			},
			onCarrierSelectedRow: function(row) {
				this.currentCarrierSelectedRow = row;
				this.carrierProductConfig.MemberCode = row.MemberCode;
			},
			carrierProductAlert: function() {
				if (this.dataSource.LogisticserCode == null || this.dataSource.LogisticserCode == undefined || this
					.dataSource.LogisticserCode ==
					'') {
					this.Utils.messageBox("请选择承运商");
					return;
				}
				this.carrierProductConfig.MemberCode = this.dataSource.LogisticserCode;
				this.carrierProductOptions.visible = true;
			},
			onCarrierProductOk: function() {
				var row = this.currentCarrierProductSelectRow;
				if (row instanceof Object && row.MemberProductCode != "" && row.MemberProductCode != null) {
					if (this.carrierConfig.IsSuccess) {
						if (this.dataSource.MemberProductCode != row.MemberProductCode) {
							this.dataSource.LogisticsNo = null;
							this.dataSource.OrderExtend.MarkDestination = null;
							this.dataSource.OrderExtend.PackageName = null;
						}
						this.dataSource.MemberProductName = row.MemberProductName;
						this.dataSource.MemberProductCode = row.MemberProductCode;
						this.carrierConfig.IsSuccess = false;
					}
				}
				//更新运费支付方式
				//this.freightPayTypeChanged();
			},
			onCarrierProductRow: function(row) {
				this.currentCarrierProductSelectRow = row;
			},
		},
		components: {
			"Shop": resolve => {
				require(['../../../selector/shopselector.vue'], resolve)
			},
			"Carrier": resolve => {
				require(['../../../selector/carrierselector.vue'], resolve)
			},
			"Warehouse": resolve => {
				require(['../../../selector/warehouseselector.vue'], resolve)
			},
			"Product": resolve => {
				require(['../../../selector/productselector.vue'], resolve)
			},
			"Receiver": resolve => {
				require(['../../../selector/receiverselector.vue'], resolve)
			},
			"ImportOrderProduct": resolve => {
				require(['../../components/importorderproduct.vue'], resolve)
			},
			"CarrierProduct": resolve => {
				require(['../../../selector/CarrierProduct.vue'], resolve)
			},
			"RelationOrderList": resolve => {
				require(['../../components/relationorderlist.vue'], resolve)
			},
			"AnnexList": resolve => {
				require(['./annexlist.vue'], resolve)
			}, //附件记录


		}
	}
</script>